/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Container, Row, Col, Button, Spinner,
} from 'react-bootstrap';
import moment from 'moment';
import MultiSelect from 'react-multiselect-checkboxes';
import QueryString from 'query-string';
import {
  CellLayout, CustomDropdown, CustomModal, CustomTable,
} from '../../component/common';
import {
  allocationTeam, storeLifeCycleState, storeLifeCycle, storeCategory, storeTag,
  storeLifeCycleHistory, storeProfile, testOrder, storecomments, fraudStore, listTags,
  categoryLabel,
} from '../../assets/api/axios';
import { dateString, shopTags } from '../../utilities/Utils';
import { getUserName } from '../../utilities/Storage';
import RetailerStoryForm from '../../layouts/review-panal/RetailerStoryForm';
import FraudDetails from '../fraud-management/FraudDetails';
import Permission from '../../access&permissions/permission';
import RetailerPayments from '../../layouts/retailer-management/RetailerPayments';
import PhoneNumberLogs from './PhoneNumberLogs';
import PromoterBrandingBDEVisits from '../promoter-branding-activities-BDE-Visits/PromoterBrandingBDEVisits';
import RelistStoreForm from '../../layouts/relist-store/RelistStoreForm';

function getStatusIcon(status) {
  return status
    ? <span className="text-success">&#10004;</span>
    : <span className="text-danger">&#10006;</span>;
}

const editableTextFieldInputMeta = {
  whatsappNumber: {
    label: 'ADD WHATSAPP NUMBER',
    placeholder: 'Add Whatsapp Number',
    maxLength: 10,
    permissions: [Permission.RETAILER_PERMISSION_ON_WHATSAPP_NUMBER,
      Permission.RETAILER_WRITE],
  },
  storeAddress: {
    label: 'ADD STORE ADDRESS',
    placeholder: 'Add Store Address',
    maxLength: '',
    permissions: [Permission.RETAILER_PERMISSION_ON_STORE_ADDRESS,
      Permission.RETAILER_WRITE],
  },
  generalNote: {
    label: 'ADD NOTE',
    placeholder: 'Add Note',
    maxLength: '',
    permissions: [Permission.RETAILER_PERMISSION_ON_GENERAL_NOTE,
      Permission.RETAILER_WRITE],
  },
  bdeLead: {
    label: 'ADD BDE',
    placeholder: 'BDE Code (5 char Req.)',
    maxLength: 5,
    permissions: [Permission.RETAILER_EXPLICIT_PERMISSION_ON_BDE_LEAD],
  },
  name: {
    label: 'ADD STORE NAME',
    placeholder: 'Add Store Name',
    maxLength: '',
    permissions: [Permission.RETAILER_EXPLICIT_PERMISSION_ON_STORE_NAME],
  },
  businessName: {
    label: 'ADD STORE DISPLAY NAME',
    placeholder: 'Add Store Display Name',
    maxLength: '',
    permissions: [Permission.RETAILER_EXPLICIT_PERMISSION_ON_STORE_DISPLAY_NAME],
  },
  ownerName: {
    label: 'ADD OWNER NAME',
    placeholder: 'Add Owner Name',
    maxLength: '',
    permissions: [Permission.RETAILER_EXPLICIT_PERMISSION_ON_OWNER_NAME],
  },
  phoneNumber: {
    label: 'ADD PRIMARY NUMBER',
    placeholder: 'Add Primary Phone Number',
    maxLength: 10,
    permissions: [Permission.RETAILER_EXPLICIT_PERMISSION_ON_PRIMARY_PHONE],
  },
  alternatePhoneNumber: {
    label: 'ADD SECONDARY NUMBER',
    placeholder: 'Add Secondary Phone Number',
    maxLength: 10,
    permissions: [Permission.RETAILER_PERMISSION_ON_SECONDARY_PHONE,
      Permission.RETAILER_WRITE],
  },
};

class RetailerDetails extends Component {
  constructor(props) {
    super(props);
    const { userPermission, location } = this.props;
    const params = QueryString.parse(location.search);
    this.canIEdit = userPermission.includes(Permission.RETAILER_WRITE);
    this.checkPers = (pers) => pers.reduce(
      (acc, item) => acc || userPermission.includes(item), false,
    );
    this.state = {
      dueOn: new Date(),
      newState: '',
      reassignTo: '',
      lastComment: '',
      allocationTeamFilters: [],
      storeLifeCycleStateFilters: [],
      showModal: false,
      loading: true,
      error: false,
      recordSubmitStatus: '',
      assignmentHistory: [],
      historyLoading: true,
      historyError: false,
      showAssignmentHistory: false,
      testOrderStatus: '',
      showAllComments: false,
      allComments: [],
      addComment: false,
      commentToBeAdded: '',
      addCommentStatus: '',
      showFraud: false,
      fraudComment: '',
      fraudSubmitStatus: '',
      runFraudStatus: '',
      updateCategory: false,
      allCategories: null,
      selectedCategoryId: null,
      submittingStatus: '',
      showShopTags: false,
      editField: {},
      editFieldInputValues: {},
      editFieldStatus: {},
      showRetailerStory: false,
      updateAddress: false,
      address: '',
      buildingName: '',
      streetName: '',
      landmark: '',
      pinCode: '',
      submittingAddressStatus: '',
      errorHeaderMsg: '',
      errorBodyMsg: '',
      showErrorMsg: false,
      errorMessage: '',
      fraudModal: '',
      showFraudModal: false,
      errorField: '',
      showRetailerPayments: false,
      shopTagListLoading: false,
      shopTagList: {},
      shopTagListError: false,
      patchShopTagStatus: '',
      selectedShopTag: 'KEY_ACCOUNT',
      otherShopTag: '',
      phoneLogsModal: false,
      showPromoterActModal: false,
      showViewVisitsModal: false,
      relistStore: false,
      isDelistedStore: '',
      params,
      categoryLabels: [],
      selectedCategoryLabels: null,
      categoryLabelLoading: false,
      storeTags: [],
      storeTagLoading: false,
      storeTagError: false,
    };
  }

  componentDidMount = () => {
    this.handleGetFilterData();
    this.getCategoryLabels();
    this.getStoreTag();
  }

  getCategoryLabels = () => {
    this.setState({
      categoryLabelLoading: true,
      categoryLabelError: false,
      categoryLabels: [],
      selectedCategoryLabels: [],
      selectedStoreTags: [],
    });
    const { retailerDetails } = this.props;
    categoryLabel('GET', retailerDetails.code)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            categoryLabels: res.data.data || [],
            categoryLabelLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          categoryLabelError: true,
          categoryLabelLoading: false,
        });
      });
  };

  handleCategoryChange = (selectedItems) => {
    if (!Array.isArray(selectedItems)) {
      this.setState({ categoryLabelError: true });
      return;
    }
    this.setState({
      selectedCategoryLabels: selectedItems,
      selectedStoreTags: [],
      categoryLabelError: false,
    }, () => {
      this.saveStoreTags(selectedItems);
    });
  };

  getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => `${placeholderButtonLabel}: ${Array.isArray(value) ? value.length : 0} selected`;

  getStoreTag = () => {
    this.setState({ storeTagLoading: true, storeTagError: false });
    const { retailerDetails } = this.props;
    storeTag('GET', retailerDetails.code)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            storeTags: res.data.data || [],
            selectedStoreTags: res.data.data || [],
            storeTagLoading: false,
          });
        } else {
          this.setState({
            storeTagError: true,
            storeTagLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          storeTagError: true,
          storeTagLoading: false,
        });
      });
  };

  saveStoreTags = (selectedTags) => {
    if (!Array.isArray(selectedTags)) {
      this.setState({ storeTagError: true });
      return;
    }
    this.setState({ storeTagLoading: true, storeTagError: false });
    const { retailerDetails } = this.props;
    const storeCode = retailerDetails.code;
    const payload = {
      tags: selectedTags,
    };
    storeTag('POST', storeCode, payload)
      .then((res) => {
        if (res.data.success) {
          this.setState({
            selectedStoreTags: selectedTags,
            storeTagError: false,
            storeTagLoading: false,
          });
        } else {
          this.setState({
            storeTagError: true,
            storeTagLoading: false,
          });
        }
      })
      .catch(() => {
        this.setState({
          storeTagError: true,
          storeTagLoading: false,
        });
      });
  };

  getAssignmentHistory = () => {
    this.setState({ historyLoading: true });
    const { retailerDetails } = this.props;
    storeLifeCycleHistory(
      'GET',
      retailerDetails.code,
    ).then((res) => {
      this.setState({
        assignmentHistory: res.data.results,
        historyLoading: false,
      });
    }).catch(() => {
      this.setState({
        historyLoading: false,
        historyError: true,
      });
    });
  }

  getShopTagList = () => {
    this.setState({ shopTagListLoading: true });
    const { retailerDetails } = this.props;
    listTags(
      'GET',
      retailerDetails.code,
      null,
    ).then((res) => {
      this.setState({
        shopTagList: res.data.tags,
        shopTagListLoading: false,
      });
    }).catch(() => {
      this.setState({
        shopTagListLoading: false,
        shopTagListError: true,
      });
    });
  }

  handleAddShopTagChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleSubmitShopTag = () => {
    this.setState({ patchShopTagStatus: 'loading' });
    const {
      selectedShopTag, otherShopTag, shopTagList,
    } = this.state;
    const { retailerDetails } = this.props;
    const shopTag = [];
    shopTagList.map((item) => shopTag.push(item.tag));
    if (shopTag.includes(otherShopTag || selectedShopTag)) {
      this.setState({
        patchShopTagStatus: 'alreadyExist',
        otherShopTag: '',
      });
      return;
    }
    listTags(
      'POST',
      '',
      {
        tag: otherShopTag || selectedShopTag,
        storeId: retailerDetails.code,
      },
    ).then(() => {
      this.setState({
        patchShopTagStatus: 'submit',
        otherShopTag: '',
      });
      this.getShopTagList();
    }).catch(() => {
      this.setState({
        patchShopTagStatus: 'error',
      });
    });
  }

  deleteTag = (id) => {
    this.setState({ shopTagListLoading: true });
    listTags(
      'DELETE',
      id,
      null,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          shopTagListLoading: false,
          patchShopTagStatus: 'submit',
        });
        this.getShopTagList();
      }
    }).catch(() => {
      this.setState({
        shopTagListLoading: false,
        shopTagListError: true,
      });
    });
  }

  handleGetFilterData = () => {
    this.setState({ loading: true });
    allocationTeam('GET')
      .then((res) => {
        this.setState({
          allocationTeamFilters: res.data.results,
        }, () => {
          storeLifeCycleState('GET')
            .then((res1) => {
              this.setState({
                storeLifeCycleStateFilters: res1.data.results,
                loading: false,
              });
            })
            .catch(() => {
              this.setState({
                loading: false,
                error: true,
              });
            });
        });
      })
      .catch(() => {
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  handleSubmitAssignmentRecord = () => {
    this.setState({ recordSubmitStatus: 'loading' });
    const {
      dueOn, lastComment, newState, reassignTo,
    } = this.state;
    const { retailerDetails } = this.props;
    const assignmentRecordData = {
      storeCode: retailerDetails.code,
      dueOn: `${moment(dueOn).format('YYYY-MM-DDTHH:mm:ss')}`,
      state: newState,
      comment: lastComment,
      assignedTo: reassignTo,
      addedFrom: 'CRM Move Assignment Section',
    };
    storeLifeCycle(
      'POST', {
        body: { ...assignmentRecordData },
      },
    )
      .then(() => {
        this.setState({
          showModal: false,
          recordSubmitStatus: 'submit',
        }, window.location.reload());
      })
      .catch(() => {
        this.setState({
          recordSubmitStatus: 'error',
        });
      });
  }

  handleSubmitAddress = () => {
    this.setState({
      submittingAddressStatus: 'loading',
    });
    const {
      address, buildingName, streetName, landmark, pinCode,
    } = this.state;
    const { retailerDetails, updateRetailerDetails } = this.props;
    storeProfile(
      'PATCH',
      retailerDetails.code,
      {
        address,
        buildingName,
        streetName,
        landmark,
        pinCode,
      },
    ).then((res) => {
      this.setState({
        updateAddress: false,
        submittingAddressStatus: 'submit',
      });
      updateRetailerDetails({
        completeAddress: res.data.completeAddress,
        pinCode: res.data.pinCode,
      });
    }).catch(() => {
      this.setState({
        submittingAddressStatus: 'error',
      });
    });
  }

  handleCreateTestOrder = () => {
    const { retailerDetails, history } = this.props;
    this.setState({
      testOrderStatus: 'submitting',
    });
    testOrder(
      'post',
      retailerDetails.code,
      null,
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          testOrderStatus: 'success',
        }, () => {
          setTimeout(() => {
            history.push(`/retailer/${retailerDetails.code}?view=order`);
          }, 1000);
        });
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        testOrderStatus: 'error',
      });
    });
  }

  handlePatchBooleanField = (field, value) => {
    const { retailerDetails, updateRetailerDetails } = this.props;
    storeProfile(
      'patch',
      retailerDetails.code,
      {
        [field]: value,
      },
    ).then(() => {
      this.setState({
        errorField: '',
        errorMessage: '',
      });
      updateRetailerDetails({ [field]: value });
    })
      .catch((error) => {
        this.setState(() => {
          if (
            error
            && error.response
            && error.response.status === 400
            && error.response.data
            && error.response.data[0]
          ) {
            return {
              errorMessage: error.response.data[0].client_message,
              errorField: field,
            };
          }
          return {
            errorMessage: 'Oops Something went wrong. Please try again!!',
            errorField: field,
          };
        });
      });
  }

  handlePatchField = (field, value) => {
    const { retailerDetails, updateRetailerDetails } = this.props;
    storeProfile(
      'patch',
      retailerDetails.code,
      {
        [field]: value,
      },
    ).then(() => {
      this.setState({
        errorField: '',
        errorMessage: '',
      });
      updateRetailerDetails({ [field]: value });
    })
      .catch((error) => {
        this.setState(() => {
          if (
            error
            && error.response
            && error.response.status === 400
            && error.response.data
            && error.response.data[0]
          ) {
            return {
              errorMessage: error.response.data[0].client_message,
              errorField: field,
            };
          }
          return {
            errorMessage: 'Oops Something went wrong. Please try again!!',
            errorField: field,
          };
        });
      });
  }

  handlePatchTextField = (field) => {
    const { editFieldInputValues, editFieldStatus, editField } = this.state;
    const { retailerDetails, updateRetailerDetails } = this.props;
    this.setState({
      editFieldStatus: {
        ...editFieldStatus,
        [field]: 'submitting',
      },
    });
    let additionalPayload = {};
    if (field === 'whatsappNumber') {
      if (retailerDetails.whatsappNumber !== editFieldInputValues[field]) {
        additionalPayload = {
          isWhatsappNotificationOpted: !!editFieldInputValues[field],
        };
      }
    }

    let numberEdit;
    if (field === 'phoneNumber' || field === 'alternatePhoneNumber') {
      numberEdit = true;
    }

    storeProfile(
      'patch',
      retailerDetails.code,
      {
        [field]: editFieldInputValues[field],
        ...additionalPayload,
      },
      {
        numberEdit,
      },
    ).then((res) => {
      if (res.status === 200) {
        this.setState({
          editFieldStatus: {
            ...editFieldStatus,
            [field]: '',
          },
          editField: {
            ...editField,
            [field]: false,
          },
        });
        updateRetailerDetails({
          [field]: editFieldInputValues[field],
          ...field === 'whatsappNumber' ? {
            isWhatsappNotificationOpted: res.data.isWhatsappNotificationOpted,
          } : {},
        });
      } else {
        throw new Error();
      }
    }).catch((error) => {
      const res = error.response;
      if (res && res.status === 400) {
        this.setState({
          editFieldStatus: {
            ...editFieldStatus,
            [field]: '',
          },
          editField: {
            ...editField,
            [field]: false,
          },
          errorHeaderMsg: res.data.header,
          errorBodyMsg: res.data.server_response,
          showErrorMsg: true,
        });
        return;
      }
      this.setState({
        editFieldStatus: {
          ...editFieldStatus,
          [field]: 'error',
        },
      });
    });
  }

  getAndPatchTextField = (field, value) => {
    const {
      editField, editFieldInputValues, editFieldStatus,
    } = this.state;
    return (
      <>
        {editField[field]
          ? (
            <>
              {
                editFieldStatus[field] === 'submitting'
                  ? (
                    <div
                      className="text-center"
                    >
                      <Spinner
                        variant="primary"
                        animation="border"
                      />
                    </div>
                  ) : editFieldStatus[field] === 'error'
                    ? (
                      <div
                        className="text-center text-danger"
                      >
                        Oops! Something went wrong.&nbsp;
                        <div>
                          <Button
                            variant="link"
                            className="py-0 "
                            onClick={() => this.handlePatchTextField(field)}
                          >
                            Retry
                          </Button>
                          <Button
                            variant="link"
                            className="py-0 "
                            onClick={() => {
                              this.setState({
                                editField: {
                                  ...editField,
                                  [field]: false,
                                },
                                editFieldStatus: {
                                  ...editFieldStatus,
                                  [field]: '',
                                },
                              });
                            }}
                          >
                            Cancel
                          </Button>
                        </div>
                      </div>
                    ) : (
                      <textarea
                        className="form-control bg-white text-medium fs-02"
                        name={field}
                        maxLength={editableTextFieldInputMeta[field].maxLength}
                        placeholder={editableTextFieldInputMeta[field].placeholder}
                        value={editFieldInputValues[field]}
                        // eslint-disable-next-line jsx-a11y/no-autofocus
                        autoFocus
                        onChange={(event) => {
                          this.setState({
                            editFieldInputValues: {
                              ...editFieldInputValues,
                              [field]: event.target.value,
                            },
                          });
                        }}
                        onKeyPress={(e) => {
                          if (e.which === 13) {
                            this.handlePatchTextField(field);
                          }
                        }}
                        disabled={!this.checkPers(editableTextFieldInputMeta[field].permissions)}
                        onBlur={() => {
                          this.setState({
                            editField: {
                              ...editField,
                              [field]: false,
                            },
                          });
                        }}
                      />
                    )
              }
            </>
          ) : (
            <div>
              <Button
                variant="link"
                className="text-medium fs-02 p-0"
                onClick={() => {
                  this.setState({
                    editField: {
                      ...editField,
                      [field]: true,
                    },
                    editFieldInputValues: {
                      ...editFieldInputValues,
                      [field]: value,
                    },
                  });
                }}
              >
                {value || (
                  this.checkPers(editableTextFieldInputMeta[field].permissions) ? (
                    <span
                      className="fs-01 pt-1 text-right cursor-pointer text-primary"
                    >
                      {editableTextFieldInputMeta[field].label}
                    </span>
                  ) : 'NA'
                )}
              </Button>
            </div>
          )}
      </>
    );
  }

  showAllocationForm = () => {
    const {
      dueOn, lastComment, loading, error, newState, reassignTo,
      allocationTeamFilters, storeLifeCycleStateFilters, recordSubmitStatus,
    } = this.state;

    const newAllocTeams = allocationTeamFilters.map((allocTeam) => ({
      label: allocTeam,
      value: allocTeam,
    }));

    const newLifeCycles = storeLifeCycleStateFilters.map((lifeCycle) => ({
      label: lifeCycle,
      value: lifeCycle,
    }));

    const newAllocTeamFilters = [
      ...newAllocTeams,
      {
        label: 'None',
        value: '',
      },
    ];

    const newLifeCycleFilters = [
      ...newLifeCycles,
      {
        label: 'None',
        value: '',
      },
    ];

    return (
      <Container>
        <Row>
          <Col
            xs={24}
            className={loading || error ? 'text-center py-3' : 'py-3 border'}
          >
            {loading ? (
              <Spinner
                variant="primary"
                animation="border"
              />
            ) : error ? (
              <>
                <div
                  className="text-danger"
                >
                  <b>Oops Something Went Wrong While Fetching Filters</b>
                </div>
                <div>
                  <Button
                    variant="outline-primary"
                    className="py-2"
                    onClick={this.handleGetFilterData}
                  >
                    Retry
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Row>
                  <Col
                    xs={8}
                  >
                    Reassign To :
                  </Col>
                  <Col
                    xs={16}
                  >
                    <select
                      name="reassignTo"
                      id="reassignTo"
                      value={reassignTo}
                      className="form-control rounded-0"
                      onChange={(event) => {
                        this.setState({
                          reassignTo: event.target.value,
                        });
                      }}
                    >
                      {newAllocTeamFilters.map((filter) => (
                        <option
                          value={filter.value}
                        >
                          {filter.label}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row
                  className="pt-3"
                >
                  <Col
                    xs={8}
                  >
                    Now Due On :
                  </Col>
                  <Col
                    xs={16}
                  >
                    <input
                      className="form-control rounded-0"
                      type="datetime-local"
                      name="dueOn"
                      value={moment(dueOn).format('YYYY-MM-DDTHH:mm')}
                      onChange={(event) => {
                        this.setState({
                          dueOn: new Date(event.target.value),
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  className="pt-3"
                >
                  <Col
                    xs={8}
                  >
                    Move State To :
                  </Col>
                  <Col
                    xs={16}
                  >
                    <select
                      name="newState"
                      id="newState"
                      value={newState}
                      className="form-control rounded-0"
                      onChange={(event) => {
                        this.setState({
                          newState: event.target.value,
                        });
                      }}
                    >
                      {newLifeCycleFilters.map((filter) => (
                        <option
                          value={filter.value}
                        >
                          {filter.label}
                        </option>
                      ))}
                    </select>
                  </Col>
                </Row>
                <Row
                  className="pt-3"
                >
                  <Col
                    xs={8}
                  >
                    Comment :
                  </Col>
                  <Col
                    xs={16}
                  >
                    <input
                      className="form-control rounded-0"
                      type="text"
                      name="lastComment"
                      value={lastComment}
                      onChange={(event) => {
                        this.setState({
                          lastComment: event.target.value,
                        });
                      }}
                    />
                  </Col>
                </Row>
                <Row
                  className="pt-3 text-center"
                >
                  <Col
                    xs={24}
                  >
                    {recordSubmitStatus === 'loading'
                      ? (
                        <Spinner
                          variant="primary"
                          animation="border"
                        />
                      ) : (
                        <>
                          {recordSubmitStatus === 'error'
                            && (
                              <div
                                className="text-danger pb-1"
                              >
                                Oops Something Went Wrong. Please try Again!!
                              </div>
                            )}
                          <Button
                            block
                            variant="primary"
                            className="py-1"
                            disabled={
                              !lastComment
                              || !newState
                              || !reassignTo
                            }
                            onClick={this.handleSubmitAssignmentRecord}
                          >
                            Save
                          </Button>
                        </>
                      )}
                  </Col>
                </Row>
              </>
            )}
          </Col>
        </Row>
      </Container>
    );
  }

  updateAddressForm = () => {
    const {
      address, buildingName, streetName, landmark, pinCode,
      submittingAddressStatus,
    } = this.state;
    return (
      <Container>
        <Row>
          <Col
            xs={24}
            className="py-3 border"
          >
            <Row>
              <Col
                xs={8}
              >
                Address :
              </Col>
              <Col
                xs={16}
              >
                <textarea
                  className="form-control"
                  rows={3}
                  name="address"
                  value={address}
                  onChange={(event) => {
                    this.setState({
                      address: event.target.value,
                    });
                  }}
                  readOnly
                />
              </Col>
            </Row>
            <Row
              className="pt-3"
            >
              <Col
                xs={8}
              >
                Building Name :
              </Col>
              <Col
                xs={16}
              >
                <input
                  className="form-control"
                  type="text"
                  name="buildingName"
                  value={buildingName}
                  onChange={(event) => {
                    this.setState({
                      buildingName: event.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row
              className="pt-3"
            >
              <Col
                xs={8}
              >
                Street Name :
              </Col>
              <Col
                xs={16}
              >
                <input
                  className="form-control"
                  type="text"
                  name="streetName"
                  value={streetName}
                  onChange={(event) => {
                    this.setState({
                      streetName: event.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row
              className="pt-3"
            >
              <Col
                xs={8}
              >
                Landmark :
              </Col>
              <Col
                xs={16}
              >
                <input
                  className="form-control"
                  type="text"
                  name="landmark"
                  value={landmark}
                  onChange={(event) => {
                    this.setState({
                      landmark: event.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row
              className="pt-3"
            >
              <Col
                xs={8}
              >
                PinCode :
              </Col>
              <Col
                xs={16}
              >
                <input
                  className="form-control"
                  type="text"
                  name="pinCode"
                  value={pinCode}
                  onChange={(event) => {
                    this.setState({
                      pinCode: event.target.value,
                    });
                  }}
                />
              </Col>
            </Row>
            <Row
              className="pt-3 text-center"
            >
              <Col
                xs={24}
              >
                {submittingAddressStatus === 'loading'
                  ? (
                    <Spinner
                      variant="primary"
                      animation="border"
                    />
                  ) : (
                    <>
                      {submittingAddressStatus === 'error'
                        && (
                          <div
                            className="text-danger pb-1"
                          >
                            Oops Something Went Wrong. Please try Again!!
                          </div>
                        )}
                      <Button
                        block
                        variant="primary"
                        className="py-1"
                        onClick={this.handleSubmitAddress}
                      >
                        Save
                      </Button>
                    </>
                  )}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }

  showComments = () => {
    const { retailerDetails } = this.props;
    this.setState({
      loading: true,
      error: false,
    });
    storecomments(
      'GET',
      retailerDetails.code,
      Date.now() - (3 * 24 * 60 * 60 * 1000),
      Date.now(),
    ).then((res) => {
      this.setState({
        allComments: res.data.comments,
        loading: false,
        error: false,
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });
  }

  addComment = (isFirstComment) => {
    const { commentToBeAdded } = this.state;
    const { retailerDetails } = this.props;
    storecomments(
      'POST',
      retailerDetails.code,
      null, null,
      {
        comment: commentToBeAdded,
        username: getUserName(),
      },
    ).then(() => {
      this.setState({
        addCommentStatus: '',
        addComment: false,
      }, () => {
        if (isFirstComment) {
          window.location.reload();
        } else {
          this.showComments();
        }
      });
    }).catch(() => {
      this.setState({
        addCommentStatus: 'error',
        addComment: !!isFirstComment,
        commentToBeAdded: isFirstComment ? commentToBeAdded : '',
      });
    });
  }

  handleStoreFraud = () => {
    const { retailerDetails } = this.props;
    const { fraudComment } = this.state;
    fraudStore(
      'PUT',
      retailerDetails.code,
      {
        status: 'FRAUD',
        comments: [fraudComment],
      },
    ).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        this.setState({
          showFraud: false,
          fraudComment: '',
        });
      } else {
        throw new Error();
      }
    }).catch(() => {

    });
  }

  handleRunFraud = () => {
    this.setState({
      runFraudStatus: 'loading',
    });
    const { retailerDetails } = this.props;
    fraudStore(
      'POST',
      null,
      {
        id: retailerDetails.code,
      },
    ).then((res) => {
      if (res.status >= 200 && res.status < 300) {
        this.setState({
          runFraudStatus: 'success',
        });
        window.location.reload();
      } else {
        throw new Error();
      }
    }).catch(() => {
      this.setState({
        runFraudStatus: 'error',
      });
    });
  }

  getCategoryList = () => {
    this.setState({
      loading: true,
      error: false,
    });
    storeCategory(
      'GET',
    ).then((res) => {
      this.setState({
        allCategories: res.data.results,
        loading: false,
        error: false,
      });
    }).catch(() => {
      this.setState({
        error: true,
        loading: false,
      });
    });
  }

  submitSelectedCategory = () => {
    const { retailerDetails } = this.props;
    const { selectedCategoryId } = this.state;
    storeProfile(
      'PATCH',
      retailerDetails.code,
      {
        storeTypeId: selectedCategoryId,
      },
    ).then(() => {
      this.setState({
        submittingStatus: '',
        error: false,
        selectedCategoryId: null,
        allCategories: null,
        updateCategory: false,
      }, () => window.location.reload());
    }).catch(() => {
      this.setState({
        submittingStatus: 'error',
      });
    });
  }

  toggleRetailerPaymentDialog = () => {
    this.setState((state) => ({ showRetailerPayments: !state.showRetailerPayments }));
  };

  togglePromoterActivityModal = () => {
    this.setState((state) => ({ showPromoterActModal: !state.showPromoterActModal }));
  };

  toggleViewVisitsModal = () => {
    this.setState((state) => ({ showViewVisitsModal: !state.showViewVisitsModal }));
  };

  render() {
    const { retailerDetails, history, userPermission } = this.props;
    const {
      showModal, assignmentHistory, historyError, params,
      historyLoading, showAssignmentHistory, testOrderStatus,
      showAllComments, allComments, loading, error, addComment,
      commentToBeAdded, addCommentStatus, showFraud,
      fraudComment, fraudSubmitStatus, runFraudStatus,
      updateCategory,
      allCategories, selectedCategoryId, submittingStatus, showShopTags,
      showRetailerStory, updateAddress, fraudModal, errorMessage,
      showErrorMsg, errorHeaderMsg, errorBodyMsg, showFraudModal,
      errorField, shopTagList, shopTagListLoading, shopTagListError, patchShopTagStatus,
      showRetailerPayments, phoneLogsModal, showPromoterActModal, showViewVisitsModal, relistStore,
      isDelistedStore,
      selectedCategoryLabels, categoryLabels, storeTagError, categoryLabelLoading,
      categoryLabelError, storeTagLoading, storeTags, selectedStoreTags,
    } = this.state;
    const tags = shopTags(
      retailerDetails.storeMatrix, retailerDetails.createdOn, Number(retailerDetails.avgRating),
    );
    const { primaryTag } = tags;
    delete tags.primaryTag;
    const conf = [
      {
        key: 'onboarding',
        label: 'Onboarding Status',
      },
      {
        key: 'overview',
        label: 'Overview',
      },
      {
        key: 'catalog',
        label: 'Catalog',
      },
      {
        key: 'retailersOwnStory',
        label: 'Retailer Story',
      },
      {
        key: 'call',
        label: 'Call Logs',
      },
      {
        key: `pendingSettlement&status=PENDING&storeIds=${retailerDetails.code}`,
        label: 'Pending Settlement',
      },
      {
        key: `reconciledSettlement&storeIds=${retailerDetails.code}`,
        label: 'Reconciled Settlement',
      },
      {
        key: 'setting',
        label: 'Delivery Setting',
      },
      {
        key: 'order',
        label: 'Orders',
      },
      {
        key: 'product',
        label: 'Product',
      },
      {
        key: 'bulkProductAddition',
        label: 'Bulk Product Addition',
      },
      {
        key: 'reviews',
        label: 'Reviews & Rating',
      },
      {
        key: 'feed',
        label: 'Retailer Feeds',
      },
      {
        key: 'specialOffer',
        label: 'Special Offers',
      },
      {
        key: 'retailerPayments',
        label: 'Retailer Payments',
      },
      {
        key: 'offerClass',
        label: 'Offer Class',
      },
      {
        key: 'commentHistory',
        label: 'Comment History',
      },
      {
        key: 'retailerWallet',
        label: 'LoveLocal Coins',
      },
    ];

    if (retailerDetails.isDelisted === true) {
      conf.push({
        key: 'relistStore',
        label: 'Relist Store',
      });
    }

    const currentAssignmentDetails = [
      {
        id: 1,
        key: 'Assigned To :',
        value: retailerDetails.assignedTo || 'NA',
      },
      {
        id: 2,
        key: 'Due On :',
        value: retailerDetails.storeLifeCycleDueOn
          ? (
            <>
              {
                (new Date().getDate() || new Date().getDate() - 1)
                  >= new Date(retailerDetails.storeLifeCycleDueOn).getDate()
                  ? (
                    <div className="text-danger">{dateString(retailerDetails.storeLifeCycleDueOn)}</div>
                  ) : (
                    <div>{dateString(retailerDetails.storeLifeCycleDueOn)}</div>
                  )
              }
            </>
          )
          : 'NA',
      },
      {
        id: 3,
        key: 'State :',
        value: retailerDetails.storeLifeCycleState || 'NA',
      },
      {
        id: 4,
        key: 'Last Comment :',
        value: retailerDetails.storeLifeCycleComment || 'NA',
      },
    ];

    const historyHeader = [
      {
        key: 'id',
        displayText: 'Id',
      },
      {
        key: 'assignedToTeam',
        displayText: 'Assigned To',
      },
      {
        key: 'assignedByUsername',
        displayText: 'Assigned By',
      },
      {
        key: 'createdOn',
        displayText: 'Created On Date',
        renderer: (data) => (<>{dateString(data.createdOn)}</>),
      },
      {
        key: 'dueOn',
        displayText: 'Due On Date',
        renderer: (data) => (<>{dateString(data.dueOn)}</>),
      },
      {
        key: 'state',
        displayText: 'State',
      },
      {
        key: 'comment',
        displayText: 'Comment',
      },
    ];

    const shopTagListHeader = [
      {
        key: 'tag',
        displayText: 'Tag',
        renderer: (data) => (data.tag),
      },
      {
        key: 'createdOn',
        displayText: 'Created On',
        renderer: (data) => (<>{data.createdOn}</>),
      },
      {
        key: 'deleteTag',
        displayText: '',
        renderer: (data) => (
          <Button
            onClick={() => { this.deleteTag(data.id); }}
          >
            Delete Tag
          </Button>
        ),
      },
    ];

    const allCommentsHeader = [
      {
        key: 'date',
        displayText: 'Date',
      },
      {
        key: 'user',
        displayText: 'User',
      },
      {
        key: 'comment',
        displayText: 'Comment',
      },
    ];

    const allCommentsTable = (
      <Container
        fluid
      >
        {loading
          ? (
            <div
              className="text-center pt-3"
            >
              <Spinner
                variant="primary"
                animation="border"
              />
            </div>
          ) : error
            ? (
              <div
                className="text-center text-danger pt-3"
              >
                <div
                  className="text-danger"
                >
                  Oops Something Went Wrong!!
                </div>
                <div
                  className="pt-3"
                >
                  <Button
                    variant="primary"
                    onClick={() => {
                      this.showComments();
                    }}
                  >
                    Retry
                  </Button>
                </div>
              </div>
            ) : (
              <Container
                fluid
              >
                <Row
                  className="pt-2 pl-1"
                >
                  {
                    !addComment
                      ? (
                        <Col
                          xs={24}
                          className="px-0 fs-01"
                        >
                          <Button
                            variant="link"
                            onClick={() => {
                              this.setState({
                                addComment: true,
                              });
                            }}
                            disabled={!this.checkPers([Permission.RETAILER_WRITE,
                              Permission.RETAILER_PERMISSION_ON_LAST_RETAILER_COMMENT])}
                          >
                            ADD COMMENT
                          </Button>
                          {addCommentStatus === 'error' && (
                            <span
                              className="text-center text-danger pt-3 fs-02"
                            >
                              Oops! Something went wrong please try again.
                            </span>
                          )}
                        </Col>
                      ) : addCommentStatus === 'submitting'
                        ? (
                          <div
                            className="text-center pt-3"
                          >
                            <Spinner
                              variant="primary"
                              animation="border"
                            />
                          </div>
                        ) : (
                          <textarea
                            className="bg-white form-control fs-02 text-medium p-2 mb-2 w-75"
                            placeholder="Add Comment"
                            value={commentToBeAdded}
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            onChange={(event) => {
                              this.setState({
                                commentToBeAdded: event.target.value,
                              });
                            }}
                            onKeyPress={(e) => {
                              if (e.which === 13) {
                                this.addComment(false);
                              }
                            }}
                            onBlur={() => {
                              this.setState({
                                commentToBeAdded: '',
                              });
                            }}
                          />
                        )
                  }
                </Row>
                <Row>
                  <CustomTable
                    headers={allCommentsHeader}
                    content={allComments}
                    keyField="date"
                    isPaginated={false}
                    totalItems={allComments.length}
                  />
                </Row>
              </Container>
            )}
      </Container>
    );

    const historyTable = (
      <Container
        fluid
      >
        <Row>
          <Col
            xs={24}
          >
            {
              historyLoading ? (
                <div
                  className="text-center pt-3"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </div>
              ) : historyError ? (
                <div
                  className="text-center text-danger pt-3"
                >
                  <div
                    className="text-danger"
                  >
                    Oops Something Went Wrong!!
                  </div>
                  <div
                    className="pt-3"
                  >
                    <Button
                      variant="primary"
                      onClick={this.getAssignmentHistory}
                    >
                      Retry
                    </Button>
                  </div>
                </div>
              ) : (
                <>
                  <CustomTable
                    headers={historyHeader}
                    content={assignmentHistory}
                    keyField="id"
                    isPaginated={false}
                    totalItems={assignmentHistory.length}
                  />
                </>
              )
            }
          </Col>
        </Row>
      </Container>
    );

    const allCategoryHeader = [
      {
        key: 'id',
        displayText: 'Select',
        renderer: (item) => (
          <input
            type="radio"
            checked={
              (selectedCategoryId === item.id)
              || (!selectedCategoryId && retailerDetails.storeTypeId === item.id)
            }
            onChange={() => {
              this.setState({
                selectedCategoryId: item.id,
              });
            }}
          />
        ),
      },
      {
        key: 'displayName',
        displayText: 'Name',
      },
      {
        key: 'description',
        displayText: 'Description',
      },
      {
        key: 'image',
        displayText: 'Image',
        renderer: (item) => (
          <div>
            <img alt="" src={item.image} height={50} />
          </div>
        ),
      },
    ];

    const shopTagsModalBody = () => (
      <Col xs={24} className="mx-0 pt-4">
        {
          Object.keys(tags).map((key) => (
            <Row className="p-2 textCentered mx-0">
              <Col xs="auto" className={`rounded-circle w-50p h-50p p-1 font-weight-bold fs-01 ${tags[key].color[0]}`}>
                <div className={`rounded-circle w-100 h-100 textCentered bg-white ${tags[key].color[1]}`}>
                  {tags[key].shortHand}
                </div>
              </Col>
              <Col>
                {tags[key].fullForm}
              </Col>
              <Col xs="auto" className="ml-auto">
                {tags[key].matrics}
              </Col>
            </Row>
          ))
        }
        {/* <Row className="mx-0 mt-5 text-secondary">
          <Col xs={24} className="mb-2">Criteria for Primary tagging :</Col>
          <Col xs={24} className="mb-1">
            1. Shop with Order Fulfillment rate in last 30 days is &gt;= 90%.  &rarr;
            <span className="pl-2 text-green">Green</span>
          </Col>
          <Col xs={24} className="mb-1">
            2. Shop with Order Fulfillment rate in last 30 days is &gt;= 75% && &lt; 90%.  &rarr;
            <span className="pl-2 text-warning">Yellow</span>
          </Col>
          <Col xs={24} className="mb-1">
            3. Shop with Order Fulfillment rate in last 30 days is &lt; 75%.  &rarr;
            <span className="pl-2 text-danger">Red</span>
          </Col>
          <Col xs={24} className="mb-1">4. Newly acquired shops in last 30 days  &rarr; White</Col>
      </Row> */}
        <Row>
          <Col
            xs={24}
          >
            {
              shopTagListLoading ? (
                <div
                  className="text-center pt-3"
                >
                  <Spinner
                    variant="primary"
                    animation="border"
                  />
                </div>
              ) : shopTagListError ? (
                <div
                  className="text-center text-danger pt-3"
                >
                  <div
                    className="text-danger"
                  >
                    Oops Something Went Wrong!!
                  </div>
                  <div
                    className="pt-3"
                  >
                    <Button
                      variant="primary"
                      onClick={this.getShopTagList}
                    >
                      Retry
                    </Button>
                  </div>
                </div>
              ) : shopTagList
                  && shopTagList.length > 0 && (
                    <>
                      <CustomTable
                        headers={shopTagListHeader}
                        content={shopTagList}
                        keyField="id"
                        isPaginated={false}
                        totalItems={shopTagList.length}
                      />
                    </>
              )
            }
          </Col>
        </Row>
        <Row
          className="pb-3"
        >
          <Col
            xs="auto"
          >
            <select
              name="selectedShopTag"
              onChange={(e) => { this.handleAddShopTagChange(e); }}
            >
              <option value="KEY_ACCOUNT">Key Account</option>
              <option value="PAID_ACCOUNT">Paid Account</option>
              <option value="PREMIUM_SHOP">Premium Shop</option>
              <option value="ADVANCED_GROWTH">Advanced Growth</option>
              <option value="ENTERPRISE">Enterprise</option>
            </select>
          </Col>
          <Col
            xs="auto"
          >
            {patchShopTagStatus === 'loading'
              ? (
                <Spinner
                  variant="primary"
                  animation="border"
                />
              ) : (
                <>
                  {patchShopTagStatus === 'error'
                            && (
                              <div
                                className="text-danger pb-1"
                              >
                                Oops Something Went Wrong. Please try Again!!
                              </div>
                            )}
                  <Button
                    block
                    variant="primary"
                    className="py-1"
                    onClick={this.handleSubmitShopTag}
                  >
                    Add Shop Tag
                  </Button>
                </>
              )}
          </Col>
          <Row>
            <Col
              xs="auto"
            >
              { patchShopTagStatus === 'alreadyExist' && (
              <div
                className="text-danger p-1"
              >
                This Tag Already Exist!!!
              </div>
              )}
            </Col>
          </Row>
        </Row>
      </Col>
    );

    return (
      <Container
        id="retailer-details"
        className="mx-0 h-100 pb-1"
      >
        {
          showFraudModal && (
            <FraudDetails
              {...this.props}
              key={retailerDetails.code}
              show={showFraudModal}
              onHide={() => {
                this.setState({
                  showFraudModal: false,
                });
              }}
              storeId={retailerDetails.code}
            />
          )
        }
        <CustomModal
          show={showShopTags}
          title="Shop Tags"
          onHide={() => {
            this.setState({
              showShopTags: false,
            });
          }}
          closeButton
          body={shopTagsModalBody()}
        />
        <CustomModal
          show={showModal}
          title="Assignment Record"
          onHide={() => {
            this.setState({
              showModal: false,
              recordSubmitStatus: '',
            });
          }}
          closeButton
          body={this.showAllocationForm()}
        />
        <CustomModal
          show={updateAddress}
          title="Update Store Address"
          onHide={() => {
            this.setState({
              updateAddress: false,
              submittingAddressStatus: '',
            });
          }}
          closeButton
          body={this.updateAddressForm()}
        />
        <CustomModal
          show={showErrorMsg}
          title={errorHeaderMsg}
          onHide={() => {
            this.setState({
              showErrorMsg: false,
              errorHeaderMsg: '',
              errorBodyMsg: '',
            });
          }}
          closeButton
          body={(
            <Container>
              <Row>
                <Col
                  xs={24}
                  className="py-3 border"
                >
                  <Row
                    className="pt-2"
                  >
                    <Col>
                      {errorBodyMsg}
                    </Col>
                  </Row>
                  <Row
                    className="pt-4 text-center"
                  >
                    <Col>
                      <Button
                        variant="primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            showErrorMsg: false,
                            errorHeaderMsg: '',
                            errorBodyMsg: '',
                          });
                        }}
                      >
                        Okay
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={showAssignmentHistory}
          title="Assignment History"
          closeButton
          onHide={() => {
            this.setState({
              showAssignmentHistory: false,
            });
          }}
          size="xl"
          body={historyTable}
        />
        <CustomModal
          show={showAllComments}
          title={`Comments - ${retailerDetails.code}`}
          closeButton
          size="lg"
          onHide={() => {
            this.setState({
              allComments: [],
              showAllComments: false,
            }, window.location.reload());
          }}
          body={allCommentsTable}
        />
        <CustomModal
          show={!!fraudModal}
          title="Fraud Store"
          onHide={() => {
            this.setState({
              fraudModal: '',
            }, () => {
              window.location.reload();
            });
          }}
          closeButton
          autoSize
          body={(
            <Container>
              <Row>
                <Col
                  xs={24}
                  className="py-3 border"
                >
                  <Row
                    className="pt-2"
                  >
                    <Col
                      className="text-danger text-center"
                    >
                      <b>{fraudModal}</b>
                    </Col>
                  </Row>
                  <Row
                    className="pt-4 text-center"
                  >
                    <Col>
                      <Button
                        variant="primary"
                        className="py-1"
                        onClick={() => {
                          this.setState({
                            fraudModal: '',
                          }, () => {
                            window.location.reload();
                          });
                        }}
                      >
                        Okay
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={showFraud}
          title="Mark Store Fraud"
          closeButton
          size="lg"
          onHide={() => {
            this.setState({
              showFraud: false,
              fraudComment: '',
              fraudSubmitStatus: '',
            });
          }}
          body={(
            <Container
              fluid
              className="py-3"
            >
              <Row>
                <Col
                  xs={18}
                >
                  <input
                    type="text"
                    value={fraudComment}
                    placeholder="Enter Fraud Reason"
                    name="fraudComment"
                    className="form-control w-100"
                    onChange={(event) => {
                      this.setState({
                        fraudComment: event.target.value,
                      });
                    }}
                  />
                </Col>
                <Col
                  xs={6}
                  className="text-center"
                >
                  {
                    fraudSubmitStatus === 'loading'
                      ? (
                        <Spinner
                          variant="primary"
                          animation="border"
                        />
                      ) : (
                        <Button
                          variant="primary"
                          block
                          onClick={() => {
                            this.handleStoreFraud('mark');
                          }}
                          disabled={!fraudComment}
                        >
                          Submit
                        </Button>
                      )
                  }
                </Col>
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={updateCategory}
          title="Update Category"
          size="md"
          closeButton
          onHide={() => {
            this.setState({
              updateCategory: false,
              allCategories: null,
              submittingStatus: '',
              selectedCategoryId: null,
              loading: false,
            });
          }}
          body={(
            <Container
              fluid
            >
              {
                loading && (
                  <div
                    className="text-center"
                  >
                    <Spinner
                      variant="primary"
                      animation="border"
                    />
                  </div>
                )
              }
              {
                !loading && error && (
                  <div
                    className="text-center text-danger pt-3"
                  >
                    Oops! Something went wrong.&nbsp;
                    <Button
                      variant="link"
                      className="py-0 "
                      onClick={() => {
                        this.getCategoryList();
                      }}
                    >
                      Retry
                    </Button>
                  </div>
                )
              }
              <Row>
                {
                  selectedCategoryId && (
                    <Col
                      xs={24}
                      className="bg-white position-sticky py-2 d-flex align-items-center"
                    >
                      <Button
                        variant="outline-primary"
                        onClick={() => {
                          this.setState({
                            submittingStatus: 'submitting',
                          }, () => {
                            this.submitSelectedCategory();
                          });
                        }}
                        disabled={submittingStatus === 'submitting'}
                      >
                        Update
                      </Button>
                      {
                        submittingStatus === 'submitting' && (
                          <span
                            className="pl-3"
                          >
                            <Spinner
                              variant="primary"
                              animation="border"
                            />
                          </span>
                        )
                      }
                      {
                        submittingStatus === 'error' && (
                          <span
                            className="text-danger pl-3"
                          >
                            Oops! Something went wrong.&nbsp;
                          </span>
                        )
                      }
                    </Col>
                  )
                }
                {
                  !!allCategories && (
                    <CustomTable
                      headers={allCategoryHeader}
                      content={allCategories}
                      keyField="id"
                      isPaginated={false}
                      totalItems={allCategories.length}
                    />
                  )
                }
              </Row>
            </Container>
          )}
        />
        <CustomModal
          show={showRetailerStory}
          body={(
            <RetailerStoryForm
              editing
              approving={false}
              storeId={retailerDetails.code}
              userPermission={userPermission}
            />
          )}
          title="Retailer Story"
          closeButton
          onHide={() => {
            this.setState({
              showRetailerStory: false,
            });
          }}
          size="lg"
        />

        {
          retailerDetails.isDelisted === true ? (
            <CustomModal
              show={relistStore}
              body={(
                <RelistStoreForm
                  editing
                  approving={false}
                  storeId={retailerDetails.code}
                  userPermission={userPermission}
                  onClose={(success) => {
                    if (success) {
                      this.setState({
                        relistStore: false,
                      });
                    }
                  }}
                />
              )}
              title="Relist Store"
              closeButton
              onHide={() => {
                this.setState({
                  relistStore: false,
                });
              }}
              size="lg"
            />
          ) : ''
        }

        {
          showPromoterActModal && retailerDetails && (
            <PromoterBrandingBDEVisits
              showPromoterActModal={showPromoterActModal}
              onClose={this.togglePromoterActivityModal}
              storeId={retailerDetails.code}
              isTabType="promoter"
            />
          )
        }
        {
          showViewVisitsModal && retailerDetails && (
            <PromoterBrandingBDEVisits
              showViewVisitsModal={showViewVisitsModal}
              onClose={this.toggleViewVisitsModal}
              storeId={retailerDetails.code}
              isTabType="bdeVisit"
            />
          )
        }
        {
          showRetailerPayments && (
            <RetailerPayments
              show={showRetailerPayments}
              onClose={() => this.toggleRetailerPaymentDialog()}
              storeId={retailerDetails.code}
            />
          )
        }
        {
          phoneLogsModal && (
            <PhoneNumberLogs
              show={phoneLogsModal}
              onHide={() => { this.setState({ phoneLogsModal: false }); }}
              phoneLogs={retailerDetails.phoneNumberChangeLogs || []}
            />
          )
        }
        <Row
          className="h-100 align-items-start
        align-content-start"
        >
          <Col
            xs={{ span: 24, order: 2 }}
            md={{ span: 10, order: 1 }}
            lg={{ span: 10, order: 1 }}
            className="px-2 h-100 pt-5 pt-md-0"
          >
            <CellLayout
              data={[
                {
                  header: 'Code',
                  content: retailerDetails.code,
                },
                ...retailerDetails.onDelivery ? [
                  {
                    header: 'Store Name',
                    content: retailerDetails.name || '-- --',
                  },
                ] : [
                  {
                    header: 'Store Name',
                    content: this.getAndPatchTextField('name', retailerDetails.name),
                  },
                ],
                ...retailerDetails.onDelivery ? [
                  {
                    header: 'Business Name',
                    content: retailerDetails.businessName || '-- --',
                  },
                ] : [
                  {
                    header: 'Business Name',
                    content: this.getAndPatchTextField('businessName', retailerDetails.businessName),
                  },
                ],
                ...retailerDetails.onDelivery ? [
                  {
                    header: 'Owner Name',
                    content: retailerDetails.ownerName || '-- --',
                  },
                ] : [
                  {
                    header: 'Owner Name',
                    content: this.getAndPatchTextField('ownerName', retailerDetails.ownerName),
                  },
                ],
                {
                  header: 'Retailer Phone Number',
                  content: retailerDetails.retailerPhoneNumber || '-- --',
                },
                {
                  header: 'Primary Phone',
                  content: (
                    <>
                      {
                        retailerDetails.phoneNumber
                          ? (
                            <Row>
                              <Col>
                                {retailerDetails.phoneNumber}
                              </Col>
                              <Col>
                                <Button
                                  className="fs-0 py-1"
                                  size="sm"
                                  onClick={() => { this.setState({ phoneLogsModal: true }); }}
                                >
                                  See Logs
                                </Button>
                              </Col>
                            </Row>
                          )
                          : (
                            'NA'
                          )
                      }
                    </>
                  ),
                },
                {
                  header: 'Secondary Phone',
                  content: this.getAndPatchTextField('alternatePhoneNumber', retailerDetails.alternatePhoneNumber),
                },
                {
                  header: 'Whatsapp Number',
                  content: this.getAndPatchTextField('whatsappNumber', retailerDetails.whatsappNumber),
                },
                {
                  header: 'Whatsapp Notification Opted',
                  content: (
                    <CustomDropdown
                      item={{
                        key: 'isWhatsappNotificationOpted',
                        displayText: '',
                        options: [
                          {
                            label: 'True',
                            value: 'true',
                          },
                          {
                            label: 'False',
                            value: 'false',
                          },
                        ],
                      }}
                      onChange={(data) => {
                        this.handlePatchBooleanField(
                          'isWhatsappNotificationOpted',
                          !(data.isWhatsappNotificationOpted === 'false'),
                        );
                      }}
                      selectedVal={
                        (retailerDetails.isWhatsappNotificationOpted)
                          ? 'true'
                          : 'false'
                      }
                      placeholder="Select"
                      closeButton={false}
                      disabled={
                        !retailerDetails.whatsappNumber
                        || !(
                          this.checkPers([Permission.RETAILER_WRITE,
                            Permission.RETAILER_PERMISSION_ON_WHATSAPP_NOTIFICATION_OPTED])
                        )
                      }
                    />
                  ),
                },
                ...!!retailerDetails.whatsappNumber
                  && !retailerDetails.isWhatsappNotificationOpted ? [{
                    header: 'Whatsapp No. Invalid reason',
                    content: retailerDetails.whatsappNumberValidityReason || 'NA',
                  }] : [],
                {
                  header: 'Subscription Plan',
                  content: (
                    <CustomDropdown
                      item={{
                        key: 'subscriptionPlan',
                        displayText: '',
                        options: [
                          {
                            label: 'Advanced Growth',
                            value: 'ADVANCED_GROWTH',
                          },
                          {
                            label: 'Enterprise',
                            value: 'ENTERPRISE',
                          },
                          {
                            label: 'Key Account',
                            value: 'KEY_ACCOUNT',
                          },
                          {
                            label: 'Actual Key Account',
                            value: 'ACTUAL_KEY_ACCOUNT',
                          },
                          {
                            label: 'Potential Key Account',
                            value: 'POTENTIAL_KEY_ACCOUNT',
                          },
                          {
                            label: 'Churn Key Account',
                            value: 'CHURN_KEY_ACCOUNT',
                          },
                          {
                            label: 'None',
                            value: '',
                          },
                        ],
                      }}
                      onChange={(data) => {
                        this.handlePatchField(
                          'subscriptionPlan', data.subscriptionPlan,
                        );
                      }}
                      selectedVal={
                        (retailerDetails.subscriptionPlan)
                      }
                      placeholder={
                        (retailerDetails.subscriptionPlan)
                          ? (retailerDetails.subscriptionPlan)
                          : 'None'
                        }
                      closeButton={false}
                      disabled={!this.checkPers(
                        [Permission.RETAILER_EXPLICIT_PERMISSION_ON_SUBSCRIPTION_PLAN],
                      )}
                    />
                  ),
                },
                {
                  header: 'Payment Status',
                  content: (
                    <CustomDropdown
                      item={{
                        key: 'isPaidStore',
                        displayText: '',
                        options: [
                          {
                            label: 'Paid',
                            value: 'true',
                          },
                          {
                            label: 'Unpaid',
                            value: 'false',
                          },
                        ],
                      }}
                      onChange={(data) => {
                        this.handlePatchField(
                          'isPaidStore', data.isPaidStore,
                        );
                      }}
                      selectedVal={(retailerDetails.isPaidStore).toString()}
                      placeholder="Select"
                      closeButton={false}
                      disabled={!this.checkPers(
                        [Permission.RETAILER_EXPLICIT_PERMISSION_ON_SUBSCRIPTION_PLAN],
                      )}
                    />
                  ),
                },
                {
                  header: 'Order Auto Acceptance',
                  content: (
                    <CustomDropdown
                      item={{
                        key: 'orderAutoAccept',
                        displayText: '',
                        options: [
                          {
                            label: 'True',
                            value: 'true',
                          },
                          {
                            label: 'False',
                            value: 'false',
                          },
                        ],
                      }}
                      onChange={() => {
                        this.handlePatchBooleanField('isAutoAccepted', !retailerDetails.isAutoAccepted);
                      }}
                      selectedVal={(retailerDetails.isAutoAccepted).toString()}
                      placeholder="Select"
                      closeButton={false}
                      disabled={!this.checkPers(
                        [Permission.RETAILER_PERMISSION_ON_ORDER_AUTO_ACCEPTANCE],
                      )}
                    />
                  ),
                },
                {
                  header: 'Class of Shop',
                  content: (
                    <CustomDropdown
                      item={{
                        key: 'classOfStore',
                        displayText: '',
                        options: [
                          {
                            label: 'Class A+',
                            value: 'Class A+',
                          },
                          {
                            label: 'Class A',
                            value: 'Class A',
                          },
                          {
                            label: 'Class B',
                            value: 'Class B',
                          },
                          {
                            label: 'Class C',
                            value: 'Class C',
                          },
                          {
                            label: 'None',
                            value: '',
                          },
                        ],
                      }}
                      onChange={(data) => {
                        this.handlePatchField(
                          'classOfStore', data.classOfStore,
                        );
                      }}
                      selectedVal={
                        (retailerDetails.classOfStore)
                      }
                      placeholder={
                        (retailerDetails.classOfStore)
                          ? (retailerDetails.classOfStore)
                          : 'None'
                        }
                      closeButton={false}
                      disabled={!this.checkPers(
                        [Permission.RETAILER_EXPLICIT_PERMISSION_ON_SUBSCRIPTION_PLAN],
                      )}
                    />
                  ),
                },
                {
                  header: 'Whatsapp Number Updated By',
                  content: retailerDetails.whatsappNumberUpdatedBy || 'NA',
                },
                {
                  header: 'Verification Call Status',
                  content: retailerDetails.verificationCallStatus || 'NA',
                },
                {
                  header: 'Web URL',
                  content: (
                    <Button
                      variant="link"
                      href={retailerDetails.url}
                      target="_blank"
                      className="p-0 fs-0"
                    >
                      {retailerDetails.url}
                    </Button>),
                },
                {
                  header: 'Team',
                  content: retailerDetails.assignedTo,
                },
                {
                  header: 'On Home Delivery',
                  content: getStatusIcon(retailerDetails.onDelivery),
                },
                {
                  header: 'Servicing',
                  content: getStatusIcon(retailerDetails.deliveryStatus),
                },
                {
                  header: 'Source',
                  content: retailerDetails.source,
                },
                ...!retailerDetails.deliveryStatus
                  ? [
                    {
                      header: 'Turn On Time',
                      content: (
                        <div>
                          {
                            retailerDetails.isPermenentlyClose
                              ? (
                                <span
                                  className="text-danger"
                                >
                                  PERMANENTLY CLOSED
                                </span>
                              )
                              : dateString(retailerDetails.TurnOnTime)
                          }
                        </div>
                      ),
                    },
                    {
                      header: 'Turn Off Reason',
                      content: retailerDetails.turnOffReason,
                    },
                    {
                      header: 'Turned Off By',
                      content: retailerDetails.turnedOffBy,
                    },
                  ]
                  : [],
                {
                  header: 'Category',
                  content: (
                    <Row>
                      {
                        retailerDetails.storeTypeName && (
                          <span
                            className="fs-02 test-medium float-left px-3"
                          >
                            {retailerDetails.storeTypeName}
                          </span>
                        )
                      }
                      <Button
                        variant="link"
                        onClick={() => {
                          this.setState({
                            updateCategory: true,
                          }, () => {
                            this.getCategoryList();
                          });
                        }}
                        className="fs-01 pl-3 pt-0 text-right curson-pointer text-primary"
                        disabled={!(
                          this.checkPers([Permission.RETAILER_WRITE,
                            Permission.RETAILER_PERMISSION_ON_CATEGORY])
                        )}
                      >
                        {retailerDetails.storeTypeName ? 'UPDATE' : 'ASSIGN CATEGORY'}
                      </Button>
                    </Row>
                  ),
                },
                {
                  header: 'Sub-Category Label',
                  content: (
                    <div>
                      {categoryLabelLoading ? (
                        <p className="text-muted">Loading categories...</p>
                      ) : categoryLabelError ? (
                        <p className="text-danger">Failed to load categories. Try again.</p>
                      ) : categoryLabels.length === 0 ? (
                        <p className="text-muted">No Data Available</p>
                      ) : (
                        <MultiSelect
                          id="subCategoryLabel"
                          placeholderButtonLabel="Select Sub-Category Label"
                          getDropdownButtonLabel={this.getDropdownButtonLabel}
                          onChange={(value) => {
                            const selectedItems = value.map((item) => item.value);
                            this.handleCategoryChange(selectedItems);
                          }}
                          options={categoryLabels.map((label) => ({
                            label,
                            value: label,
                          }))}
                          value={
                            selectedStoreTags?.map((tag) => ({
                              label: tag,
                              value: tag,
                            })) || []
                          }
                          className="minw-200p fs-02"
                          isDisabled={categoryLabelLoading || categoryLabelError}
                        />
                      )}
                      {storeTagError && (
                        <p className="text-danger">Failed to save store tags. Please retry.</p>
                      )}
                    </div>
                  ),
                },
                {
                  header: 'Pincode',
                  content: retailerDetails.pinCode,
                },
                {
                  header: 'Address',
                  content: retailerDetails.address,
                },
                {
                  header: 'Store Address',
                  content: (
                    <Row>
                      <Button
                        variant="link"
                        className={`fs-0 pl-3 pt-0 text-left ${retailerDetails.completeAddress ? 'text-muted' : 'text-primary'}`}
                        onClick={() => {
                          this.setState({
                            address: retailerDetails.address || '',
                            buildingName: retailerDetails.buildingName || '',
                            streetName: retailerDetails.streetName || '',
                            landmark: retailerDetails.landmark || '',
                            pinCode: retailerDetails.pinCode || '',
                            updateAddress: true,
                          });
                        }}
                        disabled={!(
                          this.checkPers([Permission.RETAILER_WRITE,
                            Permission.RETAILER_PERMISSION_ON_STORE_ADDRESS])
                        )}
                      >
                        {retailerDetails.completeAddress || (this.canIEdit ? 'ADD STORE ADDRESS' : 'NA')}
                      </Button>
                    </Row>
                  ),
                },
                {
                  header: 'Lat, Lng',
                  content: `${retailerDetails.latitude}, ${retailerDetails.longitude}`,
                },
                {
                  header: 'Payment Modes',
                  content: retailerDetails.paymentMode.join(', '),
                },
                {
                  header: 'Radius',
                  content: retailerDetails.radius.join(', '),
                },
                {
                  header: 'SKUs',
                  content: retailerDetails.productCount,
                },
                {
                  header: 'Customers',
                  content: retailerDetails.customerCount,
                },
                {
                  header: 'Tag',
                  content: retailerDetails.tag,
                },
                {
                  header: 'Delivery Time',
                  content: (
                    <CustomDropdown
                      item={{
                        key: 'deliveryClass',
                        displayText: '',
                        options: [
                          {
                            label: 'Within 2hrs',
                            value: 'TWOHOUR',
                          },
                          {
                            label: 'Same Day',
                            value: 'SAMEDAY',
                          },
                          {
                            label: 'Next Day',
                            value: 'NEXTDAY',
                          },
                        ],
                      }}
                      onChange={(data) => {
                        this.handlePatchBooleanField('deliveryClass', data.deliveryClass);
                      }}
                      selectedVal={retailerDetails.deliveryClass}
                      placeholder="Select"
                      closeButton={false}
                      disabled={!(
                        this.checkPers([Permission.RETAILER_WRITE,
                          Permission.RETAILER_PERMISSION_ON_DELIVERY_TIME])
                      )}
                    />),
                },
                {
                  header: 'Known Retailer',
                  content: (
                    <CustomDropdown
                      item={{
                        key: 'knownRetailer',
                        displayText: '',
                        options: [
                          {
                            label: 'True',
                            value: 'true',
                          },
                          {
                            label: 'False',
                            value: 'false',
                          },
                        ],
                      }}
                      onChange={(data) => {
                        this.handlePatchBooleanField('knownRetailer', !(data.knownRetailer === 'false'));
                      }}
                      selectedVal={(retailerDetails.knownRetailer).toString()}
                      placeholder="Select"
                      closeButton={false}
                      disabled={!(
                        this.checkPers([Permission.RETAILER_WRITE,
                          Permission.RETAILER_PERMISSION_ON_KNOWN_RETAILER])
                      )}
                    />
                  ),
                },
                {
                  header: 'General Note',
                  content: this.getAndPatchTextField('generalNote', retailerDetails.generalNote),
                },
                {
                  header: 'Store Manager',
                  content: retailerDetails.orderManager || 'NA',
                },
                {
                  header: 'Last Retailer Comment',
                  content:
                    retailerDetails.lastStoreComment
                      ? (
                        <Row>
                          <span
                            className="fs-02 test-medium float-left px-3"
                          >
                            {retailerDetails.lastStoreComment}
                          </span>
                          <Button
                            variant="link"
                            onClick={() => {
                              this.setState({
                                showAllComments: true,
                              }, () => {
                                this.showComments();
                              });
                            }}
                            className="fs-01 pl-3 pt-0 text-right curson-pointer text-primary"
                          >
                            SEE ALL
                          </Button>
                        </Row>
                      )
                      : (
                        <>
                          {
                            !addComment
                              ? (
                                <Button
                                  variant="link"
                                  className={`cursor-pointer text-primary fs-01 pl-0 pt-0
                                    ${(
                                    this.checkPers([Permission.RETAILER_WRITE,
                                      Permission.RETAILER_PERMISSION_ON_LAST_RETAILER_COMMENT])
                                  ) ? '' : 'pointer-events-none'}`}
                                  onClick={() => {
                                    this.setState({
                                      addComment: true,
                                    });
                                  }}
                                >
                                  {this.checkPers([Permission.RETAILER_WRITE,
                                    Permission.RETAILER_PERMISSION_ON_LAST_RETAILER_COMMENT])
                                    ? 'ADD COMMENT' : 'NA'}
                                </Button>
                              ) : addCommentStatus === 'submitting'
                                ? (
                                  <div
                                    className="text-center"
                                  >
                                    <Spinner
                                      variant="primary"
                                      animation="border"
                                    />
                                  </div>
                                ) : addCommentStatus === 'error'
                                  ? (
                                    <div
                                      className="text-center text-danger"
                                    >
                                      Oops! Something went wrong.&nbsp;
                                      <Button
                                        variant="link"
                                        className="py-0 "
                                        onClick={() => {
                                          this.setState({
                                            addCommentStatus: 'submitting',
                                          }, this.addComment(true));
                                        }}
                                      >
                                        Retry
                                      </Button>
                                    </div>
                                  ) : (
                                    <textarea
                                      className="bg-white form-control fs-02 text-medium p-2 mb-2"
                                      placeholder="Add Comment"
                                      value={commentToBeAdded}
                                      // eslint-disable-next-line jsx-a11y/no-autofocus
                                      autoFocus
                                      onChange={(event) => {
                                        this.setState({
                                          commentToBeAdded: event.target.value,
                                        });
                                      }}
                                      onKeyPress={(e) => {
                                        if (e.which === 13) {
                                          this.addComment(true);
                                        }
                                      }}
                                      onBlur={() => {
                                        this.setState({
                                          addComment: false,
                                          commentToBeAdded: '',
                                        });
                                      }}
                                    />
                                  )
                          }
                        </>
                      ),
                },
                {
                  header: 'BDE Lead',
                  content: this.getAndPatchTextField('bdeLead', retailerDetails.bdeLead),
                },
                {
                  header: 'Performance Category',
                  content: retailerDetails.retailerPerformanceCategory || 'NA',
                },
                {
                  header: 'Order Cancellation Rate',
                  content: retailerDetails.orderCancellationRate || 'NA',
                },
                {
                  header: 'Last Retailer Payment',
                  content: (
                    <Row className="d-flex">
                      <span
                        className="fs-02 test-medium float-left px-3"
                      >
                        {retailerDetails.storePaymentDateAndAmount || 'NA'}
                      </span>
                      {
                        retailerDetails.storePaymentDateAndAmount && (
                          <Button
                            variant="link"
                            onClick={() => this.toggleRetailerPaymentDialog()}
                            className="fs-01 pl-3 pt-0 text-right curson-pointer text-primary"
                          >
                            SEE ALL
                          </Button>
                        )
                      }
                    </Row>
                  ),
                },
                {
                  header: 'Is Store Fraud',
                  content: (
                    <Row>
                      <Col
                        xs={6}
                      >
                        <CustomDropdown
                          item={{
                            key: 'isFraudStore',
                            displayText: '',
                            options: [
                              {
                                label: 'True',
                                value: 'true',
                              },
                              {
                                label: 'False',
                                value: 'false',
                              },
                            ],
                          }}
                          onChange={(data) => {
                            if (retailerDetails.isFraudStore) {
                              if (
                                retailerDetails.fraudStatus === 'REJECTED'
                                || retailerDetails.fraudStatus === 'NOT_FLAGGED'
                              ) {
                                this.handlePatchBooleanField('isFraudStore', !(data.isFraudStore === 'false'));
                              } else {
                                this.setState({
                                  errorField: 'isFraudStore',
                                  errorMessage: 'The Store has Fraud Flags in pending state. Please reject them before making this change.',
                                });
                              }
                            } else {
                              this.setState({
                                showFraud: true,
                              });
                            }
                          }}
                          selectedVal={(retailerDetails.isFraudStore).toString()}
                          placeholder="Select"
                          closeButton={false}
                          disabled={!this.checkPers([Permission.FRAUD_ADMIN])}
                        />
                      </Col>
                      {
                        errorField === 'isFraudStore' && errorMessage && (
                          <Col
                            xs={18}
                            className="d-flex justify-content-center text-danger"
                          >
                            {errorMessage}
                          </Col>
                        )
                      }
                    </Row>
                  ),
                },
                {
                  header: 'Allowed LL funded offer',
                  content: getStatusIcon(retailerDetails.llFundedOfferAllowed),
                },
                {
                  header: 'To be Monitored',
                  content: getStatusIcon(retailerDetails.monitorFraud),
                },
                {
                  header: 'Is Test Store',
                  content: (
                    <Row>
                      <Col
                        xs={6}
                      >
                        <CustomDropdown
                          item={{
                            key: 'isTestStore',
                            displayText: '',
                            options: [
                              {
                                label: 'True',
                                value: 'true',
                              },
                              {
                                label: 'False',
                                value: 'false',
                              },
                            ],
                          }}
                          onChange={(data) => {
                            this.handlePatchBooleanField('isTestStore', !(data.isTestStore === 'false'));
                          }}
                          selectedVal={(retailerDetails.isTestStore).toString()}
                          placeholder="Select"
                          closeButton={false}
                          disabled={!this.checkPers([Permission.MARK_TEST_SHOP])}
                        />
                      </Col>
                      {
                        errorField === 'isTestStore' && errorMessage && (
                          <Col
                            xs={18}
                            className="d-flex justify-content-center text-danger"
                          >
                            {errorMessage}
                          </Col>
                        )
                      }
                    </Row>
                  ),
                },
                {
                  header: 'Does Store have a Custom Catalogue',
                  content: (
                    <Row>
                      <Col
                        xs={6}
                      >
                        <CustomDropdown
                          item={{
                            key: 'isCustomCatalogue',
                            displayText: '',
                            options: [
                              {
                                label: 'True',
                                value: 'true',
                              },
                              {
                                label: 'False',
                                value: 'false',
                              },
                            ],
                          }}
                          onChange={(data) => {
                            this.handlePatchBooleanField('isCustomCatalogue', !(data.isCustomCatalogue === 'false'));
                          }}
                          selectedVal={(retailerDetails.isCustomCatalogue).toString()}
                          placeholder="Select"
                          closeButton={false}
                          disabled={!this.checkPers([Permission.MARK_TEST_SHOP])}
                        />
                      </Col>
                      {
                        errorField === 'isCustomCatalogue' && errorMessage && (
                          <Col
                            xs={18}
                            className="d-flex justify-content-center text-danger"
                          >
                            {errorMessage}
                          </Col>
                        )
                      }
                    </Row>
                  ),
                },
                {
                  header: 'Automated Pinned Store',
                  content: getStatusIcon(retailerDetails.isPinnedStore),
                },
                {
                  header: 'Recommended Pinned Store',
                  content: getStatusIcon(retailerDetails.isRecommendedPinnedStore),
                },
              ]}
            />
          </Col>
          <Col
            xs={{ span: 24, order: 1 }}
            md={{ span: 4, order: 2 }}
            className="px-0 mx-auto"
          >
            <div
              className="nav-tab mx-auto"
            >
              {conf.map((item) => (
                <Button
                  key={item.key}
                  variant="light"
                  className="fs-0 py-2 px-4 d-block
                 rounded-0 text-left border-top"
                  onClick={() => {
                    if (item.key === 'retailersOwnStory') {
                      this.setState({ showRetailerStory: true });
                      return;
                    }
                    if (item.key === 'relistStore') {
                      this.setState({ relistStore: true });
                      return;
                    }
                    history.push(`/retailer/${retailerDetails.code}?view=${item.key}`);
                  }}
                >
                  {item.label}
                </Button>
              ))}
            </div>
          </Col>
          <Col
            xs={{ span: 20, order: 3 }}
            md={{ span: 8, order: 3 }}
            className="px-0 mb-3 mx-auto"
          >
            <Row
              className="border border-grey"
            >
              <Col
                xs={24}
                className="fs-03 text-center"
              >
                <Button
                  variant="outline-primary"
                  block
                  onClick={() => {
                    this.setState({
                      showShopTags: true,
                    });
                    this.getShopTagList();
                  }}
                  className={`${primaryTag.color[0]}`}
                >
                  Shop Tags
                </Button>
              </Col>
            </Row>
            <Row
              className="border border-grey"
            >
              <Col
                xs={24}
                className="fs-03 text-center"
              >
                <Button
                  variant="outline-primary"
                  block
                  onClick={this.togglePromoterActivityModal}
                  className={`${primaryTag.color[0]}`}
                >
                  View Promoter Activity
                </Button>
              </Col>
            </Row>
            <Row
              className="border border-grey"
            >
              <Col
                xs={24}
                className="fs-03 text-center"
              >
                <Button
                  variant="outline-primary"
                  block
                  onClick={this.toggleViewVisitsModal}
                  className={`${primaryTag.color[0]}`}
                >
                  View Visits
                </Button>
              </Col>
            </Row>
            <Row
              className="border border-grey"
            >
              <Col
                xs={24}
                className="py-2 fs-03 text-center"
              >
                <b>
                  Current Assignment
                </b>
              </Col>
              <Col
                xs={24}
              >
                {currentAssignmentDetails.map((details) => (
                  <Row
                    key={details.id}
                    className="pb-2"
                  >
                    <Col
                      xs={12}
                      className="fs-01"
                    >
                      {details.key}
                    </Col>
                    <Col
                      xs={12}
                      className="fs-01"
                    >
                      {details.value}
                    </Col>
                  </Row>
                ))}
                <Row
                  className="py-2 mx-0"
                >
                  <Col
                    xs={24}
                  >
                    <Button
                      block
                      // className="py-0"
                      onClick={() => {
                        this.setState({
                          showModal: true,
                        });
                      }}
                      disabled={!this.checkPers([Permission.MOVE_AGENT_ASSIGNMENT])}
                    >
                      Move
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                className="text-center border p-3"
              >
                <b>
                  Click on &quot;Assignment History&quot;
                  Button to see History of all assignments.
                </b>
              </Col>
              <Col
                xs={24}
                className="text-center border"
              >
                <Button
                  variant="outline-primary"
                  block
                  onClick={() => {
                    this.setState({
                      showAssignmentHistory: true,
                    });
                    this.getAssignmentHistory();
                  }}
                >
                  Assignment History
                </Button>
              </Col>
            </Row>
            <Row>
              <Col
                xs={24}
                className="text-center border p-3"
              >
                <b>
                  Click on &quot;Create Test Order&quot;
                  Button to create a Test Order.
                </b>
              </Col>
              <Col
                xs={24}
                className="text-center border"
              >
                {
                  testOrderStatus === 'submitting'
                    ? (
                      <Spinner
                        variant="primary"
                        animation="border"
                      />
                    ) : (
                      <Button
                        variant="outline-primary"
                        block
                        onClick={() => {
                          this.handleCreateTestOrder();
                        }}
                        disabled={!(
                          this.checkPers([Permission.RETAILER_WRITE,
                            Permission.RETAILER_PERMISSION_ON_CREATE_TEST_ORDER_BUTTON])
                        )}
                      >
                        Create Test Order
                      </Button>
                    )
                }
              </Col>
              {
                (
                  testOrderStatus === 'success'
                  || testOrderStatus === 'error'
                ) && (
                  <Col
                    xs={24}
                    className="text-center border py-3"
                  >
                    {
                      testOrderStatus === 'success'
                        ? (
                          <span
                            className="text-green"
                          >
                            Test Order Created Successfully!!
                          </span>
                        ) : (
                          <span
                            className="text-danger"
                          >
                            Oops Something Went Wrong!!
                          </span>
                        )
                    }
                  </Col>
                )
              }
            </Row>

            {/* Fraud Check */}
            <Row>
              <Col
                xs={24}
                className="text-center border py-3"
              >
                <div>
                  <Button
                    variant="outline-danger"
                    className={
                      retailerDetails.isFraudStore
                        ? ''
                        : retailerDetails.fraudStatus === 'REJECTED'
                          ? 'text-green'
                          : 'text-info'
                    }
                    block
                    // disabled={
                    //   !retailerDetails.fraudStatus
                    // }
                    onClick={() => {
                      this.setState({
                        showFraudModal: true,
                      });
                    }}
                  >
                    {
                      retailerDetails.isFraudStore
                        ? 'Store is Marked as Fraud'
                        : retailerDetails.fraudStatus === 'REJECTED'
                          ? 'Store Fraud is Rejected'
                          : 'Fraud Reason (Option to Accept / Reject)'
                    }
                  </Button>
                </div>
                <div
                  className="pt-1"
                >
                  {
                    runFraudStatus === 'loading'
                      ? (
                        <Spinner
                          variant="primary"
                          animation="border"
                        />
                      ) : (
                        <Button
                          variant={
                            runFraudStatus === 'error'
                              ? 'danger'
                              : 'outline-primary'
                          }
                          block
                          onClick={() => {
                            this.handleRunFraud();
                          }}
                          disabled={!(
                            this.checkPers([Permission.RETAILER_WRITE,
                              Permission.RETAILER_PERMISSION_ON_RUN_FRAUD_CHECK])
                          )}
                        >
                          {
                            runFraudStatus === 'error'
                              ? 'Retry (Run Fraud Checks)'
                              : 'Run Fraud Checks'
                          }
                        </Button>
                      )
                  }
                </div>
                {/* <div
                  className="pt-1"
                >
                  <Button
                    variant="outline-warning"
                    className="text-danger"
                    block
                    onClick={() => {
                      this.setState({
                        showFraud: true,
                      });
                    }}
                    disabled={!this.canIEdit}
                  >
                    Mark Store as Fraud
                  </Button>
                </div> */}
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    );
  }
}

RetailerDetails.propTypes = {
  retailerDetails: PropTypes.shape({
    code: PropTypes.string,
    isFraud: PropTypes.bool,
    fraudComments: PropTypes.array,
    fraudStatus: PropTypes.string,
    phoneNumber: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    alternatePhoneNumber: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    retailerPhoneNumber: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    storeMatrix: PropTypes.shape({}),
    avgRating: PropTypes.string,
    storeTags: PropTypes.arrayOf(PropTypes.shape({})),
    createdOn: PropTypes.string,
    ownerName: PropTypes.string,
    url: PropTypes.string,
    assignedTo: PropTypes.string,
    onDelivery: PropTypes.bool,
    deliveryStatus: PropTypes.bool,
    isPermenentlyClose: PropTypes.bool,
    turnOffReason: PropTypes.string,
    turnedOffBy: PropTypes.string,
    TurnOnTime: PropTypes.string,
    storeTypeId: PropTypes.number,
    storeTypeName: PropTypes.string,
    pinCode: PropTypes.string,
    address: PropTypes.string,
    completeAddress: PropTypes.string,
    storeAddress: PropTypes.string,
    latitude: PropTypes.number,
    longitude: PropTypes.number,
    paymentMode: PropTypes.array,
    radius: PropTypes.array,
    productCount: PropTypes.number,
    customerCount: PropTypes.number,
    tag: PropTypes.string,
    expectedDeliveryTime: PropTypes.string,
    knownRetailer: PropTypes.bool,
    isAutoAccepted: PropTypes.bool,
    storeLifeCycleDueOn: PropTypes.string,
    storeLifeCycleComment: PropTypes.string,
    storeLifeCycleState: PropTypes.string,
    generalNote: PropTypes.string,
    orderManager: PropTypes.string,
    lastStoreComment: PropTypes.string,
    deliveryClass: PropTypes.string,
    retailerPerformanceCategory: PropTypes.string,
    orderCancellationRate: PropTypes.number,
    bdeLead: PropTypes.string,
    whatsappNumber: PropTypes.string,
    isWhatsappNotificationOpted: PropTypes.bool,
    whatsappNumberValidityReason: PropTypes.string,
    whatsappNumberUpdatedBy: PropTypes.string,
    verificationCallStatus: PropTypes.string,
    subscriptionPlan: PropTypes.string,
    name: PropTypes.string,
    businessName: PropTypes.string,
    buildingName: PropTypes.string,
    streetName: PropTypes.string,
    landmark: PropTypes.string,
    isFraudStore: PropTypes.bool,
    llFundedOfferAllowed: PropTypes.bool,
    monitorFraud: PropTypes.bool,
    isTestStore: PropTypes.bool,
    isCustomCatalogue: PropTypes.bool,
    storePaymentDateAndAmount: PropTypes.string,
    source: PropTypes.string,
    isPaidStore: PropTypes.string,
    classOfStore: PropTypes.string,
    phoneNumberChangeLogs: PropTypes.shape([]),
    isPinnedStore: PropTypes.bool,
    isRecommendedPinnedStore: PropTypes.bool,
    isDelisted: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  updateRetailerDetails: PropTypes.func.isRequired,
  userPermission: PropTypes.arrayOf(
    PropTypes.string,
  ).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
};

export default RetailerDetails;
